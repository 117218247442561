.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.slaboFont{
  font-family: 'Slabo 27px', serif;
}

.carousel .slide img {
  max-height: 100;  /* change this to whatever you want */
  width: auto;
}



.abc{
  text-align: left;
  padding-top: 20px;
  
}
.xyz{
  padding-left: 10;
  padding-right: 20;
  text-align: left;
  margin-left: 10;
  margin-right: 10;
  align-items: center;
  align-content: center;
}

  #pqr {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
   
  }
  
  #pqr td, #pqr th {
    border: 1px solid #000000;
    padding: 8px;
  }
  
  #pqr tr:nth-child(even){background-color: #faddd1;}
  
  #pqr tr:hover {background-color: #b5bafc;}
  
  #pqr th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color:#008080;
    color: white;
  }
  .qwerty{
    padding-top: 20px;
    text-align: left;
  }





  .card1 {
    background-color: #fdd627;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin: 20px;
  }
  
  .card1 h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  
  .card1 p {
    font-size: 16px;
    margin: 10px 0;
    padding: 0;
  }
  
  .card1 span.college-name1 {
    font-size: 20px;
    font-weight: bold;
    color: #001550;
    margin-top: 20px;
    display: block;
  }
  
  @media (max-width: 768px) {
    .card1 {
      padding: 10px;
      margin: 10px;
    }
  
    .card1 h2 {
      font-size: 16px;
    }
  
    .card1 p {
      font-size: 16px;
    }
  
    .card1 span.college-name1 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .card1 {
      padding: 5px;
      margin: 5px;
    }
  
    .card1 h2 {
      font-size: 14px;
    }
  
    .card1 p {
      font-size: 14px;
    }
  
    .card1 span.college-name1 {
      font-size: 16px;
      margin-top: 5px;
    }
  }

  .loadingcenter {
    margin: auto;
    width: 50%;
  
    padding: 10px;
  }


.card3 {
   
    margin: auto;
    
    border-radius: 10px;
    box-shadow: 0px 5px 20px 0px #d2dae3;
    z-index: 1;
   padding: 10;
   width: max-content;
   
    justify-content: center;
    align-items: center
}

.card3 h6 {
    color: red;
    font-size: 20px
}

.inputs input {
    width: 40px;
    height: 40px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}


.card3-2 .content {
    margin-top: 10px
}

.card3-2 .content a {
    color: red
}

.form-control:focus {
    box-shadow: none;
    border: 2px solid red
}

.validate {
    border-radius: 20px;
    height: 40px;
    background-color: red;
    border: 1px solid red;
    width: 140px
}

.button-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 30px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 20px;
}

.primary {
  background-color: #007bff;
  color: #fff;
}

.secondary {
  background-color: #ff5c7e;
  color: #fff;
}

.button:hover {
  background-color: #004799;
}

@media (max-width: 768px) {
  .button-row {
    flex-direction: column;
    align-items: center;
  }
}
